import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | EP Agency
			</title>
			<meta name={"description"} content={"Bringing Your Vision to Life"} />
			<meta property={"og:title"} content={"Our Services | EP Agency"} />
			<meta property={"og:description"} content={"Bringing Your Vision to Life"} />
			<meta property={"og:image"} content={"https://vaiolanster.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vaiolanster.com/img/649791345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vaiolanster.com/img/649791345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vaiolanster.com/img/649791345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vaiolanster.com/img/649791345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Explore Our Comprehensive Event Services
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 180px 0px 180px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
			Welcome to the services page of EP Agency, where we transform your dreams into reality with our expert event planning and execution. We offer a wide range of services designed to make your event seamless, memorable, and extraordinary. Let us guide you through our offerings and show you how we can make your next event truly special.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://vaiolanster.com/img/3.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Event Planning and Management
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Customized Event Planning: Our team works closely with you to understand your vision and create a customized plan that reflects your style and preferences.
							<br />
							Initial Consultation: We begin with a detailed consultation to understand your goals, budget, and vision for the event.
							<br />
							Planning and Coordination: Our planners coordinate every detail, from venue selection to final execution, ensuring a smooth and seamless process.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://vaiolanster.com/img/4.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Food Service
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Catering and Menu Planning: Delicious food and beverages are essential to any event.
							<br />
							Menu Customization: Our chefs work with you to create a customized menu that suits your tastes and dietary needs.
							<br />
							Tasting Sessions: We offer tasting sessions to ensure you are completely satisfied with the menu selections.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://vaiolanster.com/img/5.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Technical Support
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Entertainment and Activities: Engaging entertainment and activities make your event memorable.
							<br />
							Entertainment Options: We offer a wide range of entertainment options, from live bands and DJs to performers and speakers.
							<br />
							Interactive Activities: Our planners can arrange interactive activities, such as photo booths, games, and workshops, to keep your guests entertained.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://vaiolanster.com/img/2.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					EP Agency
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white" width="50%">
				Contact Us for More Information
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base" width="50%">
				This is just a glimpse of what EP Agency has to offer. Our comprehensive services are designed to cater to every aspect of your event, ensuring a seamless and memorable experience. To discover the full range of our offerings and how we can make your event truly special, please contact us. Our team is ready to assist you with any questions and provide detailed information tailored to your needs.
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					background="--color-dark"
					padding="12px 28px 12px 28px"
				>
					Contact Us
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});